.sessions-wrap {
  background: url(../../app/assets/dgnLogo.svg) center/contain no-repeat;
}
.sessions-main {
  background-color: white;
  height: 100%;
  width: 100%;
  opacity: 97%;
}
.session-list-display {
  background-color: rgba(66, 0, 0, 0.97);
  color: #420000;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.session-list-item {
  background-color: #fcf5e5;
  border: 1px solid #420000;
}

.session-list-item:hover {
  transform: scale(1.03);
  transition: all 0.4s;
}

.session-list-item:active {
  transform: scale(0.98);
  transition: all 0s;
}

.session-display {
  border-radius: 0.5rem;
  background-color: rgba(252, 245, 229, 0.97);
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.session-logs-title {
  background-color: var(--dgn-950);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: #fcf5e5;
  white-space: nowrap;
  font-size: clamp(1rem, 3vw, 2rem);
}

.info-top-row {
  font-size: xx-large;
}

.details-seperating-bar {
  border-top: 1px solid rgba(66, 0, 0, 1);
}

.summary-parent {
  height: 100%;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0.1rem 0 1.25rem 0;
}

.details-chunk {
  margin: 1rem 1rem 1.5rem 1rem;
}

.session-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 42px;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.session-button:hover {
  transform: scale(1.03);
  transition: all 0.4s;
}

.session-button:active {
  transform: scale(0.98);
  transition: all 0s;
}

.edit-session {
  border: 1px solid rgb(133 77 14);
  color: rgb(133 77 14);
}

.edit-session:hover {
  background-color: rgb(254, 252, 232);
}

.delete-session {
  border: 1px solid rgb(153 27 27);
  color: rgb(153 27 27);
}

.delete-session:hover {
  background-color: rgb(254, 242, 242);
}

.update-session-button {
  display: flex;
  align-items: center;
}

.update-session-button:hover {
  transform: scale(1.03);
  transition: all 0.4s;
}
