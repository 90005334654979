/* Carousel selection styling below */
.view-button:hover {
  text-decoration: underline;
  text-underline-offset: 0.5rem;
}

.view-button {
  text-decoration: underline;
  text-decoration-color: #420000;
  text-underline-offset: 0.5rem;
  color: #420000;
}

.embla {
  overflow: hidden;
  margin: 1rem;
  height: 100%;
  --slide-spacing: 1.5rem;
}

.embla__container {
  display: flex;
  touch-action: pan-y;
  flex-direction: row;
  width: 100%;
}

.embla__slide {
  background-color: #fcf5e5;
  flex: 0 0 100%;
  height: fit-content;
  min-width: 0;
  border: 0.15rem solid #420000;
  margin-left: var(--slide-spacing);
  border-radius: 0.5rem;
}

.embla__next,
.embla__prev {
  border: 0.15rem solid #420000;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 4px;
}

.arrows {
  width: 1.5rem;
  height: 1.5rem;
  color: #420000;
}

.embla__next:hover,
.embla__prev:hover {
  background-color: #420000;
  transition: all 0.4s;
}

.embla-arrows:active {
  background-color: #672a2a;
  transition: all 0s;
}

.embla-arrows:hover > .arrows {
  color: white;
}

.empty-slide {
  background-color: rgba(140, 127, 107, 0.8);
  /* background-color: #672a2a; */
}

/* Player view below */

.hp-logo {
  width: 3rem;
  height: 3rem;
  color: #672a2a;
  stroke: white;
}

.inspo-icons {
  max-width: 4rem;
  max-height: 4rem;
}

.enter-button {
  border: 0.15rem solid #420000;
  border-radius: 3%;
  color: #420000;
  border-radius: 0.5rem;
}

.enter-button:hover {
  background-color: #420000;
  color: #fcf5e5;
  transform: scale(1.03);
  transition: all 0.4s;
}

.enter-button:active {
  background-color: #672a2a;
  transform: scale(0.98);
  transition: all 0s;
}

hr {
  border: none;
}

/* GM view below */

.gm-img-cont {
  width: 100%;
  max-height: 20rem;
  border-bottom: 0.15rem solid #420000;
}

.gm-img {
  aspect-ratio: 4 / 3;
}

.game-description {
  overflow-y: auto;
  max-height: 10rem;
}

.game-description::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #bdac91;
  border-radius: 10px;
}

.game-description::-webkit-scrollbar-thumb {
  background-color: #420000;
  height: 5px;
  border-radius: 10px;
}

.passcode {
  color: #672a2a;
}

.char-select {
  width: 100%;
  background-color: white;
  flex-grow: 1;
}

.slot-icon {
  margin: 0.5rem 0.5rem;
  font-size: 3rem;
  color: #39a8b5;
  stroke: #420000;
  stroke-width: 0.5rem;
}
