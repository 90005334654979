.waves-top {
  height: 8rem;
  position: absolute;
}

.hero-cont {
  height: 60rem;
}

/* make a media query for small screens & adjust position of bg img */

.main-header {
  font-size: 4rem;
  color: #420000;
}

.sub-header {
  font-size: 1.675rem;
}

@media (max-width: 560px) {
  .sub-header {
    font-size: 1.275rem;
  }
}

@media (max-width: 485px) {
  .sub-header {
    font-size: 0.975rem;
  }
}

@media (max-width: 400px) {
  .sub-header {
    font-size: 0.775rem;
  }
}

@media (max-width: 570px) {
  .cards-bottom-row {
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
  }

  .cards-bottom-row > .promo-play {
    margin-left: 0;
  }

  .cards-bottom-row > .promo-gather {
    margin-right: 0;
  }
}

.cta-button {
  padding: 0.5rem 1.4rem 0.5rem 1.4rem;
  border: 0.15rem solid #420000;
  font-size: large;
  color: white;
  background: rgb(66, 0, 0);
  background: linear-gradient(
    66deg,
    rgba(66, 0, 0, 1) 0%,
    rgba(103, 42, 42, 1) 100%
  );
}

.cta-button:hover {
  background: rgb(103, 42, 42);
  background: linear-gradient(
    66deg,
    rgba(103, 42, 42, 1) 0%,
    rgba(66, 0, 0, 0.636467086834734) 100%
  );
  transform: scale(1.03);
  transition: all 0.4s;
}

.cta-button:active {
  transform: scale(0.98);
  background: rgb(66, 0, 0);
  background: linear-gradient(
    66deg,
    rgba(66, 0, 0, 1) 0%,
    rgba(103, 42, 42, 1) 100%
  );
  transition: all 0s;
}

/* Carousel specific styles */
.carousel-wrapper {
  height: 50rem;
}

.group > span {
  background-color: rgba(168, 82, 77, 0.3);
}

.group > span > svg {
  color: #420000;
}

/* Content after hero stuff */

.divider {
  rotate: 180deg;
  transform: translateY(-1%);
}

.bg-waves {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1023%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(66%2c 0%2c 0%2c 1)'%3e%3c/rect%3e%3cpath d='M 0%2c71 C 72%2c98.4 216%2c206.4 360%2c208 C 504%2c209.6 576%2c91.6 720%2c79 C 864%2c66.4 936%2c156.8 1080%2c145 C 1224%2c133.2 1368%2c45 1440%2c20L1440 560L0 560z' fill='rgba(103%2c 42%2c 42%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c433 C 96%2c419.4 288%2c339.6 480%2c365 C 672%2c390.4 768%2c572.8 960%2c560 C 1152%2c547.2 1344%2c352.8 1440%2c301L1440 560L0 560z' fill='rgba(66%2c 0%2c 0%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1023'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-size: cover;
}

@media (max-width: 900px) {
  .feature-showcase {
    max-height: 40rem;
  }
}

.feature-showcase {
  height: 60rem;
  width: 100%;
}

.roadmap-img {
  background: url(../assets/DGNroadmap.png) center/contain no-repeat;
}

.feature-title {
  font-size: 4rem;
}

.feature-1 {
  height: 15rem;
  background-color: white;
  border-radius: 10px;
}

.divider-merger {
  transform: translateY(-1%) rotateX(180deg);
}

/* Card styles below */

.promo-cards {
  background-color: white;
  color: #420000;
  border-radius: 4px;

  z-index: 3;
}

.title {
  font-size: x-large;
}

.card-details {
  color: black;
}

hr {
  border: none;
}

@media (max-width: 400px) {
  .title {
    font-size: large;
  }

  .bg-waves {
    justify-content: space-between;
    flex-basis: 20%;
  }

  .promo-cards {
    height: 60%;
  }
}

.waves-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: translateY(1%) rotate(180deg);
}

.waves-bottom svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 59px;
}

.waves-bottom .shape-fill {
  fill: #420000;
}
