.archive-wrap {
  border: 0.01px solid transparent;
  background-color: white;
}

.archive-content {
  background-color: white;
}

.sub-nav {
  border: 3px solid #420000;
}

.asset-display {
  background: url(../../app/assets/dgnLogo.svg) center/contain no-repeat;
}

.party-name {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.char-item {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Session component styling */
.sessions-wrapper {
  background-color: var(--dgn-950);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sessions-list-cont {
  white-space: nowrap;
}

::-webkit-scrollbar:horizontal {
  scroll-behavior: smooth;
  height: 7px;
  background-color: transparent;
  border-top: 3px solid transparent;
  border-bottom: 1px solid grey;
  border-radius: 0;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: white;
  border-top: 3px solid transparent;
  border-radius: 10px;
  cursor: pointer;
}

.session-item {
  border-radius: 0.5rem;
}

.session-item:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: all 0.4s;
  border: 1px solid white;
}

.session-item:focus {
  transform: scale(1.05);
  transition: all 0s;
}

.session-item:focus > .session-title {
  color: rgb(17, 24, 39);
  min-width: 20ch;
}

.session-item:focus > .session-date {
  color: rgb(17, 24, 39);
}

.session-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.125rem;
  font-weight: 600;
  min-width: 10ch;
}

.session-date {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: rgb(181, 188, 200);
}

.view-session {
  border: 1px solid var(--dgn-950);
  color: white;
}

.start-session {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.start-session:hover {
  transform: scale(1.03);
  transition: all 0.4s;
}

.start-session:active {
  transform: scale(0.98);
  transition: all 0s;
}
