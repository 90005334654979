.dot {
	width: 1.5rem;
	height: 1.5rem;
	background-color: transparent;
	border: 2px solid #420000;
	border-radius: 50%;
	transition: background-color 0.3s ease;
}

.dot.filled {
	background-color: #333;
}

@media (max-width: 860px) {
	#counter-content {
		display: flex;
		flex-direction: column;
	}
}