.game-settings-main-wrapper {
  background: url(../../app/assets/dgnLogo.svg) center/contain no-repeat;
}

.game-settings-content-wrapper,
.selector-container {
  background-color: rgba(255, 255, 255, 0.88);
}

.game-settings-form {
  border-radius: 0.5rem;
  background-color: rgba(252, 245, 229, 0.88);
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-container {
  border-radius: 1%;
  width: 35rem;
  height: 25rem;
}

.img-actual {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.revert-img-button {
  background-color: rgb(254 226 226);
  border: 1px solid rgb(153 27 27);
  color: rgb(153 27 27);
}

.revert-img-button:hover {
  transform: scale(1.03);
  background-color: rgb(254, 242, 242);
  transition: all 0.4s;
}

.revert-img-button:active {
  transform: scale(0.98);
  background-color: rgb(254 202 202);
  transition: all 0s;
}

.submit-button {
  border: 1px solid rgb(22 101 52);
  color: white;
}

.submit-button:disabled {
  border: 1px solid rgb(22 101 52);
  color: white;
  background-color: gray;
}

.submit-button:hover:not([disabled]),
.delete-precious-resource-button:hover:not([disabled]),
.precious-resource-button:hover:not([disabled]) {
  transform: scale(1.03);
  transition: all 0.4s;
}

.list-chars {
  border: 1px solid #420000;
}

.border-temp {
  border: 1px solid red;
}

.entry-button:hover {
  transform: scale(1.03);
  transition: all 0.4s;
}
