.nav-wrapper {
  background-color: #420000;
  /* border-radius: 10px; */
}

.nav-items:hover {
  border-bottom: 1px solid white;
  transition: all 0.4s;
}

.nav-items:active {
  border-bottom: 1px solid white;
  transition: all 0s;
}

.nav-items:focus {
  border-bottom: 1px solid white;
}

.profile-button {
  color: rgb(209, 213, 219);
  padding: 0.5rem 1.4rem 0.5rem 1.4rem;
  background-color: #420000;
  border-radius: 10px;
}

.profile-button:hover {
  color: white;
  transition: all 0.4s;
}

.account-icon {
  color: #420000;
}

.navwave {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.navwave svg {
  position: relative;
  display: block;
  width: calc(167% + 1.3px);
  height: 25px;
  transform: rotateY(180deg);
}

.navwave .shape-fill {
  fill: #420000;
}
