.v-seperator {
  width: 1px;
  border-right: 1px solid black;
}

/* list views */
.content-body::-webkit-scrollbar {
  width: 0.5rem;
  background-color: rgb(220, 220, 220);
  border-radius: 10px;
}

.content-body::-webkit-scrollbar-thumb {
  background-color: rgb(66, 0, 0);
  height: 5px;
  border-radius: 10px;
}

.list-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
}

.no-assets {
  background-color: #420000;
  color: #fcf5e5;
}

.content-item {
  border-bottom: 2px solid rgb(66, 0, 0, 0.5);
  margin: 0 1rem 0 1rem;
}

.content-item:hover {
  border-bottom: 2px solid rgb(66, 0, 0, 1);
  transition: all 0.2s;
}

/* asset details */
.selected-content-details-wrap {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: scroll;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.selected-content-details-wrap > div:last-child {
  padding: 0.75rem;
}

.selected-content-details {
  margin: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(252, 245, 229, 0.7);
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.info-top-row {
  font-size: xx-large;
}

.info-top-row-sm {
  font-size: x-large;
  margin-top: 1rem;
  background-color: var(--dgn-950);
  text-align: center;
  color: #fcf5e5;
}

.asset-title {
  background-color: var(--dgn-950);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: #fcf5e5;
  white-space: nowrap;
  font-size: clamp(1rem, 3vw, 2rem);
}

.asset-info-main {
  height: fit-content;
}

.info-description {
  height: 100%;
  width: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0.1rem 0 1.25rem 0;
}

.info-description::-webkit-scrollbar {
  width: 0.5rem;
  background-color: rgb(220, 220, 220);
  border-radius: 10px;
}

.info-description::-webkit-scrollbar-thumb {
  background-color: rgb(66, 0, 0);
  height: 5px;
  border-radius: 10px;
}

.markdown-cont > div {
  background-color: transparent;
}

.details-chunk {
  margin: 1rem 1rem 1.5rem 1rem;
}

.related-details-title {
  background-color: var(--dgn-950);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: #fcf5e5;
}

.related-details {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.details-seperating-bar {
  border-top: 1px solid rgba(66, 0, 0, 1);
}

.related-list {
  grid-template-rows: repeat(7, minmax(0, 1fr));
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

/* .footer-accent {
  content: '';
  background: #420000;
  -webkit-mask: url(../../../app/assets/footerAccent.png) center/cover;
  mask: url(../../../app/assets/footerAccent.png) center/cover;
  height: 5rem;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 300;
  margin-top: 1rem;
} */

.border-image-container {
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border-image-source: url(../../../app/assets/dgnFrame2.png);
  border-image-slice: calc(50 / 150 * 110%) calc(80 / 230 * 100%) fill;
  border-image-width: 50px;
  height: 100%;
  overflow-y: hidden;
}

/* BUTTON STYLING BELOW */

/*
100 = rgb(254 249 195)
200 = rgb(254 240 138)
800 = rgb(133 77 14)

100 = rgb(254 226 226)
200 = rgb(254 202 202)
800 = rgb(153 27 27)

100 = rgb(237, 233, 254)
200 = rgb(221, 214, 254)
800 = rgb(91, 33, 182)

100 = rgb(255 237 213)
200 = rgb(254 215 170)
800 = rgb(154 52 18)
*/

@media screen and (max-width: 700px) {
  .row-button {
    height: clamp(1rem, 5vw, 42px);
    width: 9vw;
    font-size: 0;
    align-items: center;
  }

  .list-icon {
    height: 1rem;
    width: 1rem;
  }
}

.list-button-container {
  align-items: center;
}

.row-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 42px;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.row-button:hover:not([disabled]) {
  transform: scale(1.03);
  transition: all 0.4s;
}

.row-button:active:not([disabled]) {
  transform: scale(0.98);
  transition: all 0s;
}

.create-content-button {
  border: 1px solid rgb(22 101 52);
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.create-content-button:hover {
  transform: scale(1.03);
  transition: all 0.4s;
  background-color: rgb(240, 253, 244);
}

.create-content-button:active {
  transform: scale(0.98);
  transition: all 0s;
}

.refetch-content-button {
  border: 1px solid var(--dgn-button-text);
  color: var(--dgn-button-text);
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.refetch-content-button:hover {
  transform: scale(1.03);
  transition: all 0.4s;
  background-color: var(--dgn-button-hover);
}

.refetch-content-button:active {
  transform: scale(0.98);
  transition: all 0s;
}

.view-content {
  border: 1px solid rgb(22, 78, 99);
  color: rgb(22, 78, 99);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.view-content:hover {
  background-color: rgb(236, 254, 255);
}

.edit-content {
  border: 1px solid rgb(133 77 14);
  color: rgb(133 77 14);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.edit-content:hover {
  background-color: rgb(254, 252, 232);
}

.delete-content {
  border: 1px solid rgb(153 27 27);
  color: rgb(153 27 27);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.delete-content:hover:not([disabled]) {
  transform: scale(1.03);
  background-color: rgb(254, 242, 242);
  transition: all 0.4s;
}

.delete-content:active:not([disabled]) {
  transform: scale(0.98);
  transition: all 0s;
}

/* details of selected */
.fullscreen,
.back-button {
  color: var(--dgn-button-text);
  border: 1px solid var(--dgn-button-text);
}

.fullscreen:hover,
.back-button:hover {
  background-color: var(--dgn-button-hover);
  transition: all 0.4s;
}

.fullscreen:active,
.back-button:active {
  transition: all 0s;
}

/* comparison */
.clear-button {
  background-color: rgb(153 27 27);
  border: 1px solid rgb(153 27 27);
  color: rgb(254 226 226);
  transform: scale(0.9);
}

.clear-button:hover {
  background-color: rgb(254, 242, 242);
  color: rgb(220, 38, 38);
  transition: all 0.4s;
}

.clear-button:active {
  background-color: rgb(254 202 202);
  color: rgb(220, 38, 38);
  transition: all 0s;
}

/* MDEditor Preview Window */

.wmde-markdown {
  background-color: transparent;
}

.w-md-editor-fullscreen > .w-md-editor-content {
  background-color: rgba(252, 245, 229);
}

.w-md-editor-toolbar > ul > li > button > svg {
  transform: scale(1.25);
  color: #420000;
}
