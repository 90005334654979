#overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2c2929;
  opacity: 0.65;
}
.display-over-overlay {
  /* 1 z index lower than the char create modal */
  z-index: 9;
  position: relative !important;
}
/* #search-bar:focus + #overlay {
  display: block;
} */

#search-container {
  /* background: url('../../../app/assets/parchmentBG.jpg'); */
}
