.flowbite-modal-root > div > div > div {
  justify-content: center;
  align-items: center;
  position: relative;
}

.flowbite-modal-root > div > div > div > h3 {
  color: #420000;
  margin-left: auto;
  margin-right: auto;
}

.flowbite-modal-root > div > div > div > button {
  color: #fcf5e5;
  position: absolute;
  right: 0;
  margin-right: 0.5rem;
  background-color: #420000;
}

.flowbite-modal-root > div > div > div > button:hover {
  transform: scale(1.02);
  background-color: #420000;
  transition: all 0.4s;
}

.flowbite-modal-root > div > div > div > button:active {
  transform: scale(0.98);
  background-color: #420000;
  transition: all 0s;
}

#modal-body > div {
  max-height: 100vh;
}

.flowbite-modal-header-parent > div:last-child {
  padding: 0.75rem !important;
}
