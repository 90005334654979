.flowbite-header-parent > div:first-child {
  display: none;
}

.flowbite-header-parent > div:last-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
}

.loading-ani-white div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-ani-red div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  background-color: #420000;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-ani-blue div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  background-color: rgb(37 99 235);
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-ani-base {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-ani-base div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-ani-base div:nth-child(1) {
  left: 8px;
  animation: loading-ani-base1 0.6s infinite;
}
.loading-ani-base div:nth-child(2) {
  left: 8px;
  animation: loading-ani-base2 0.6s infinite;
}
.loading-ani-base div:nth-child(3) {
  left: 32px;
  animation: loading-ani-base2 0.6s infinite;
}
.loading-ani-base div:nth-child(4) {
  left: 56px;
  animation: loading-ani-base3 0.6s infinite;
}
@keyframes loading-ani-base1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-ani-base3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-ani-base2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
