/* Background of page */
* {
  --primary-color: none;
}

.game-wrapper {
  background: url(../../app/assets/dgnLogo.svg) center/contain no-repeat;
}

.game-content {
  background-color: rgba(255, 255, 255, 0.88);
}

.create-game-form {
  border-radius: 0.5rem;
  background-color: rgba(252, 245, 229, 0.88);
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-container {
  border-radius: 1%;
  width: 35rem;
  height: 25rem;
}

.chosen-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Foorm-footer img */

/* TODO: Decide on getting rid of this */
/* .footer-accent {
  content: '';
  background: #420000;
  -webkit-mask: url(../../app/assets/footerAccent.png) center/cover;
  mask: url(../../app/assets/footerAccent.png) center/cover;
  height: 5rem;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 300;
} */
