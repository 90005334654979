/* Custom settings for landing page image */
.landing-container {
  /* border: 3px solid blue; */
}

@media (max-width: 540px) {
  .corner {
    opacity: 0;
  }
}

.landing-above {
  top: 0;
  background-position: top;
}

.stepper-wrap {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
