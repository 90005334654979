@tailwind base;
@tailwind components;
@tailwind utilities;
.wmde-markdown > ul,
.wmde-markdown > ol {
  list-style-type: revert;
}

/* Fixes numbers and bullets not rendering in MDEditor preview  */
.wmde-markdown > ul,
.wmde-markdown > ol {
  list-style: revert;
}

* {
  box-sizing: border-box;
  font-family: Garamond, Serif;
  --dgn-950: #420000;
  --dgn-button-text: #074f57;
  --dgn-button-hover: #c3feff;
  scrollbar-width: thin;
  scrollbar-color: #074f57 transparent;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  background-color: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #074f57;
  border-radius: 10px;
}

body {
  overflow: auto !important;
}

#root {
  position: relative;
  min-height: 100vh;
}

.flowbite-file-input {
  border: none;
  background: transparent !important;
  color: #420000 !important;
  border: 1.2px solid #420000 !important;
  border-radius: 0.5rem;
}
.flowbite-file-input:hover {
  cursor: pointer;
}

.flowbite-file-input::file-selector-button {
  background-color: #420000;
  color: #fcf5e5;
  border-top-left-radius: calc(0.5rem - 1.2px);
  border-bottom-left-radius: calc(0.5rem - 1.2px);
  border: 1.2px solid #420000;
}

.flowbite-file-input::file-selector-button:hover {
  color: #fcf5e5;
  transition: all 0.2s;
  cursor: pointer;
}

/* Custom Variables */
:root {
  --primary-color: rgba(255, 230, 0, 1);
}

/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
}
