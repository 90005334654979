.footwave {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg) translateY(-1%);
}

.footwave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 25px;
}

.footwave .shape-fill {
  fill: #420000;
}
