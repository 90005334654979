.active {
  border-color: #420000 !important;
  color: #420000 !important;
}

.flowbite-select > div > select {
  background-color: #420000;
  color: #fcf5e5;
}

.submit-button {
  display: flex;
  align-items: center;
}

.submit-button:disabled {
  border: 1px solid rgb(22 101 52);
  color: white;
  background-color: gray;
  display: flex;
  align-items: center;
}

.submit-button:hover:not([disabled]) {
  transform: scale(1.03);
  transition: all 0.4s;
}

.form-clear-button {
  border: 1px solid rgb(127 29 29);
  display: flex;
  align-items: center;
}

.form-clear-button:hover {
  transform: scale(1.01);
  transition: all 0.4s;
}

.bg-wrap {
  background: url(../../app/assets/dgnLogo.svg) center/contain no-repeat;
}
.main-container {
  background-color: white;
  height: 100%;
  width: 100%;
  opacity: 98%;
}
